import { markArray, problemsArray } from "../../data/Constants";

interface Result {
  parameterValueIndex: number;
  parameterValueLabel: string;
  parameterValueCode: string;
  parameterInterference: number;
}

export class Helper {
  getImageUrl(activeStep, imageUrl: string) {
    console.log(imageUrl);
    let url = imageUrl.substring(0, imageUrl.length - 5);
    url = url + "-" + activeStep + ".jpeg";
    return url;
  }

  getProcessedCroppedImageUrl(activeStep, imageUrl: string) {
    const splitImage = imageUrl.split("/");
    splitImage[5] = "processedcropped";
    const ready = splitImage.join("/");

    let url = ready.substring(0, ready.length - 5);
    url = url + "-" + activeStep + ".jpeg";
    return url;
  }

  getCroppedImageUrl(activeStep, imageUrl: string) {
    const splitImage = imageUrl.split("/");
    splitImage[5] = "cropped";
    const ready = splitImage.join("/");

    let url = ready.substring(0, ready.length - 5);
    url = url + "-" + activeStep + ".jpeg";
    console.log(url);
    return url;
  }

  getResult(currentParameterValue, testProblem, activeStep) {
    let result: Result;

    if(testProblem) problemsArray[activeStep] = 2;
    else problemsArray[activeStep] = 1;
    
    if(activeStep === 1) result = {
      parameterValueIndex: currentParameterValue === 0 ? markArray[activeStep][7] : currentParameterValue,
      parameterValueLabel: currentParameterValue === 0 ? markArray[activeStep][7].label : markArray[activeStep][currentParameterValue].label,
      parameterValueCode: currentParameterValue === 0 ? markArray[activeStep][7].code : markArray[activeStep][currentParameterValue].code,
      parameterInterference: problemsArray[activeStep]
    };
    else result = {
      parameterValueIndex: currentParameterValue === 0 ? markArray[activeStep][1].code : currentParameterValue,
      parameterValueLabel: currentParameterValue === 0 ? markArray[activeStep][1].label : markArray[activeStep][currentParameterValue].label,
      parameterValueCode: currentParameterValue === 0 ? markArray[activeStep][1].code : markArray[activeStep][currentParameterValue].code,
      parameterInterference: problemsArray[activeStep]
    };

    return result;
  }

  getSteps() {
    return [
      "Leukozyten",
      "spez. Gewicht",
      "PH-Wert",
      "Protein",
      "Glukose",
      "Ketone",
      "Hemoglobin"
    ];
  }
}
