import React, { Component } from "react";
import { State } from "../store";
import { connect } from "react-redux";
import Breeds from "../services/firebase/breeds";
import { Grid, Typography, Button } from "@material-ui/core";
import BreedTable from "../components/BreedTable/BreedTable";
import { withStyles } from "@material-ui/core/styles";

interface BreedPageProps {}

const StyledButton = withStyles({
  root: {
    background: "#4769cc",
    borderRadius: "20px",
    border: 0,
    margin: "5px",
    marginBottom: "15px",
    padding: "10px",
    color: "#fff",
    fontSize: "12px",
    boxShadow: "0px 20px 20px 0px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      background: "#FFF",
      color: "#4769cc"
    },
    "&:focus": {
      background: "#FFF",
      color: "#4769cc"
    }
  },
  label: {
    textTransform: "capitalize"
  }
})(Button);

class BreedPage extends Component<BreedPageProps> {
  state = {
    breedsData: []
  };
  componentDidMount() {
    const breeds = Breeds.getAllBreeds()
      .then(data => {
        this.setState({
          breedsData: data
        });
      })
      .catch(error => console.log("error: ", error));
  }

  onDelete() {
    console.log("delete in page");
    this.componentDidMount();
  }

  render() {
    return (
      <Grid container direction="column">
        <Grid container direction="row" justify="space-between">
          <Typography variant="h5" style={{ marginBottom: "20px" }}>
            Neue Rassen von Firebase
          </Typography>
          <Grid item>
            <StyledButton>
              <a
                href="https://console.firebase.google.com/project/pezz-3ffb7/database/firestore/data~2F"
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
              >
                Firebase Konsole
              </a>
            </StyledButton>
            <StyledButton>
              <a
                href="https://pezz-dev.tailored-apps.com/dba/"
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
              >
                Datenbank
              </a>
            </StyledButton>
          </Grid>
        </Grid>
        <Grid>
          <BreedTable
            breeds={this.state.breedsData}
            onDelete={() => this.onDelete()}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {};
};

export const BreedPageContainer = connect(mapStateToProps)(BreedPage);
